<template>
  <div class="row mx-0 mt-3">
    <div class="col-9 p-0">
      <SelectAll
        v-model="selectedProject"
        :options="adminProjects"
        placeholder="Projects"
        displayValue="description"
        color="gold"
        size="small"
        :isSearch="true"
        width="full"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    SelectAll: defineAsyncComponent(() => import('../components/SelectAll.vue'))
  },
  name: 'DebitOrderSelectProject',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donationItem: {},
      selectedProject: null,
      originalProject: null
    }
  },
  async mounted () {
    this.originalProject = this.item.project
    this.donationItem = { ...this.item }
    await this.currentProject()
  },
  watch: {
    selectedProject () {
      if (this.selectedProject) {
        this.donationItem.project.description = this.selectedProject.description
        this.donationItem.project.projectID = this.selectedProject.projectID
        this.$emit('projectUpdated', this.donationItem.project)
      }
    }
  },
  methods: {
    currentProject () {
      if (this.adminProjects && this.adminProjects.length > 0) {
        var theProject = this.adminProjects.find(project => this.originalProject.projectID === project.projectID)
        this.selectedProject = theProject
      }
    }
  },
  computed: {
    ...mapGetters(['adminProjects'])
  }
}
</script>
